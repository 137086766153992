import React, { useRef } from "react"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const WrapperCarousel = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;
`

const ArrowPrev = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 40px;
  width: 20px;
  height: 20px;
  background: var(--bg-primary) 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--font-primary);
  font-size: 12px;

  &.next {
    right: 100px;
  }

  &.prev {
    right: 60px;
    margin-left: 5px;
  }
`

const Banner = styled.img`
  border-radius: 8px;
`

export default function CarouselComponent({ list, headerText }) {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    customPaging: function (i) {
      return <div className="dot"></div>;
    },
    dotsClass: "slick-dots slick-thumb",
  };

  const sliderRef = useRef();

  const arrowNext = () => {
    // sliderRef.current.slickNext();
    sliderRef.current.slickPrev();

  }

  const arrowPrev = () => {
    sliderRef.current.slickNext();

    // sliderRef.current.slickPrev();
  }

  return (
    <WrapperCarousel>
      <div className='headText'>{headerText}</div>
      <div className='w-full mt-10'>
        <ArrowPrev className='next' onClick={arrowNext}>
          <FontAwesomeIcon icon={faChevronLeft}/>
        </ArrowPrev>
        <Slider ref={sliderRef} {...settings}>
          {list.map((items, index) => (
            <div className='relative' key={index}>
              <Banner className="banner" src={`${items}`} alt="" />
              <p style={{
                zIndex: "11",
                position: "absolute",
                bottom: "20px",
                left: "30px",
                color: "#fff",
                fontSize: "24px",
                height: "100%"
              }}>
              </p>
            </div>
          ))}
        </Slider>
        <ArrowPrev className='prev' onClick={arrowPrev}>
          <FontAwesomeIcon icon={faChevronRight} />
        </ArrowPrev>
      </div>
    </WrapperCarousel>
  )
}